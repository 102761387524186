<template>
  <main-layout :itemMenuActive="200" tituloMenu="Mensajes de Indisponibilidad">
    <br>
    <div class="container">
      <div class="card">
        <div class="card-content">
          <div class="columns">
            <p class="column is-half title is-4 centered-text">Parametrización de Mensajes de Indisponibilidad</p>
          </div>
          <div class="columns">
            <div class="column">
              <TableCard messageEmpty="No datos" detailKey="id" 
                :columns="columns" 
                :info="data"
                :tableConfig="tableConfig"></TableCard>
            </div>
          </div>
          <div v-if="isEditable()" class="columns">
            <div class="column column is-4 is-offset-8">
              <div class="columns">
                <b-button style="margin-right: 10px" class="column" :loading="isLoading" type="is-danger" expanded
                  @click="discard">
                  Descartar
                </b-button>
                <b-button class="column" :loading="isLoading" type="is-primary" expanded 
                  @click="save"
                  :disabled="disabledguardar">
                  Guardar
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main-layout>
</template>

<script>
import MainLayout from "../../components/Layout/MainLayout";
import axios from "axios";
import TableCard from "../../components/cards/TableCard";
import {mapGetters} from "vuex";
import { config } from "../../assets/config/jsonconfig.json";
const urlBaseTiposdeDocumento = config.urlBaseTiposdeDocumento
import { getToken } from "../../assets/config/getToken.js";

export default {
  name: "AdminAlert",
  components: {
    MainLayout,
    TableCard
  },
  data() {
    return {
      isLoading: true,
      disabledguardar: false,
      data: [],
      columns: {
        estado_habilitado: {
          label: '',
          type: 'switchedestadoalertas',
          disabled: false
        },
        id: {
          label: 'ID',
          type: 'text',
        },
        tipo_mensaje: {
          label: 'Tipo de mensaje',
          type: 'text',
          sortable: true
        },
        descripcion: {
          label: 'Descripción',
          type: 'text',
          sortable: true
        },
        mensaje: {
          label: 'Mensaje',
          type: 'text',
          sortable: true
        },
        fecha_inicio: {
          label: 'Fecha inicio',
          type: 'dateIso',
          sortable: true
        },
        fecha_fin: {
          label: 'Fecha fin',
          type: 'dateIso',
          sortable: true
        },
        accion: {
          label: 'Acción',
          type: 'accionalertas',
        },
      },
      tableConfig: {
        isPaginated: true,
        isPaginationRounded: true,
        perPage: 10,
        backendSorting: true,
        isDetailed: false
      },
      roleList: this.getRoleIdList(),
      token: null
    }
  },
  async mounted() {
    this.token = await getToken(config.DOMINIO);
    this.checkRoles()
    this.cargarMSGdisponibles().then((res) => {
      //let json = JSON.parse(res);
      console.log(res);

      this.data = res;
      this.isLoading = false;
    })
  },
  methods: {
    ...mapGetters(["getRoleIdList"]),
    isEditable() {
      return this.roleList.includes(54)
    },
    isDisabled() {
      return !this.isEditable()
    },
    checkRoles() {
      if (this.isDisabled()) {
        delete this.columns.accion;
        this.columns.estado_habilitado.disabled = true;
      }
    },
    async cargarMSGdisponibles() {
      return await axios({
        method: 'get',
        url: urlBaseTiposdeDocumento + '/get_messages',
        headers: {
            'Authorization': `Bearer ${this.token.Bearer}`
        }
      }).then((res) => {
        return res.data;
      }).catch((error) => {
        console.error(error)
      })
    },
    async guardarAlertas(data) {
      return await axios({
        method: 'post',
        url: urlBaseTiposdeDocumento + '/update_messages',
        headers: {
            'Authorization': `Bearer ${this.token.Bearer}`
        },
        data: data
      }).then((res) => {
        return res.data;
      }).catch((error) => {
        console.error(error)
      })
    },
    discard: function () {
      location.reload();
    },
    save: function () {
      this.isLoading = true;
      this.$buefy.dialog.confirm({
        title: 'Alertas',
        message: '¿Estas seguro de realizar los cambios?',
        confirmText: 'Confirmar',
        cancelText: 'Cancelar',
        type: 'is-primary',
        hasIcon: false,
        onConfirm: () => {
          this.guardarAlertas(this.data).then((res) => {
            this.isLoading = false;

            if (res.message.indexOf('documents successfully') > -1) {
              location.reload();
            }
          })
        },
        onCancel: () => {
          this.isLoading = false;
        }
      })
    },
  }
}
</script>